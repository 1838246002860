import $ from 'jquery/dist/jquery'
import { updateObject } from './ajax'
import { STATUS_OK, STATUS_FAIL } from './ajax'
import { NotifySuccess, NotifyWarning } from './index'
import { Swappable } from '@shopify/draggable'

const TOAST_DURATION_SHORTER = 1500

function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

$().ready(() => {
	if (!$('.property-edit').length) return

	// uncomment this for ajax alternative
	// updateImageEdit()

	// only adds event listeners for image deletion
	reinitializeEvents()

	const gallery = document.querySelectorAll('.image-gallery')

	const swappable = new Swappable(gallery, {
		draggable: '.manipulate>img',
	})

	var lastSrcEl, lastDestEl
	swappable.on('swappable:start', (e) => {
		lastSrcEl = e.data.dragEvent.data.originalSource
	})
	swappable.on('swappable:swapped', (e) => {
		lastDestEl = e.data.swappedElement
	})
	swappable.on('swappable:stop', (e) => {
		// console.log(lastSrcEl, lastDestEl)
		if (lastSrcEl != null && lastDestEl != null && lastSrcEl.alt != lastDestEl.alt) {
			let apiResponse
				; (async () => {
					apiResponse = await updateObject(`/image/${$('#propertyId').val()}/`, {
						action: 'swap',
						body: [lastSrcEl.alt, lastDestEl.alt],
					})
					if (apiResponse.code === STATUS_OK) {
						NotifySuccess(`Pořadí obrázků bylo úspěšně změněno.`, TOAST_DURATION_SHORTER)
						const tmp = lastSrcEl.alt
						lastSrcEl.alt = lastDestEl.alt
						lastDestEl.alt = tmp
						lastSrcEl = lastDestEl = null
					} else if (apiResponse.code === STATUS_FAIL)
						NotifyWarning(`Při změně pořadí obrázků došlo k chybě.`, TOAST_DURATION_SHORTER)
					else {
						NotifyWarning('Při zpracování požadavku došlo k chybě.')
					}
				})()
		}
	})
})

// ajax alternative for editing images
// async function updateImageEdit() {
//     (async () => {
//         var html = await loadTemplateObject({
//             type: 'image.edit',
//             options: $("#propertyId").val()
//         })
//         $('.image-gallery').html(html)
//         if (html === STATUS_FAIL) {
//             console.log('An error has occured while loading image editor.')
//             return
//         }
//         reinitializeEvents()
//     })()
// }

function reinitializeEvents() {
	$('.image-deletion').click(function () {
		var image = $(this).prev()[0]
		const imageId = image.alt
		$.confirm({
			title: 'Smazání obrázku',
			content: 'Opravdu si přejete smazat tento obrázek?',
			buttons: {
				confirm: {
					text: 'Potvrdit',
					btnClass: 'btn-red',
					action: () => {
						let apiResponse
							; (async () => {
								apiResponse = await updateObject(`/image/${$('#propertyId').val()}/`, {
									action: 'delete',
									body: imageId,
								})
								if (apiResponse.code === STATUS_OK) {
									NotifySuccess(`Obrázek byl úspěšně smazán.`)
									$(this).parent().remove()

									// ajax alternative
									// updateImageEdit()
								} else if (apiResponse.code === STATUS_FAIL)
									NotifyWarning(`Při mazání obrázku došlo k chybě.`)
								else NotifyWarning(`Při zpracování požadavku došlo k chybě.`)
							})()
					},
				},
				cancel: {
					text: 'Zrušit',
					action: () => { },
				},
			},
		})
	})
}
