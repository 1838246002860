import $ from 'jquery/dist/jquery'
import { deleteObject, updateObject } from './ajax'
import { STATUS_OK, STATUS_FAIL } from './ajax'
import { NotifySuccess, NotifyWarning } from './index'

export function delay(callback, ms) {
	var timer = 0
	return function () {
		var context = this,
			args = arguments
		clearTimeout(timer)
		timer = setTimeout(function () {
			callback.apply(context, args)
		}, ms || 0)
	}
}

export const FILTER_UPDATE_DELAY = 350
export const PROPERTY_TYPES = ['flat', 'comm', 'house', 'build']

function getCellValue(row, index) {
	return $(row).children('td').eq(index).text()
}

function rowComparator(index) {
	return function (a, b) {
		var valA = getCellValue(a, index),
			valB = getCellValue(b, index)
		if (isNaN(parseInt(valA))) {
			return valA.toString().localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' })
		} else {
			if (valA.includes('Kč') || valA.includes('m')) {
				valA = parseInt(valA.slice(0, -3).replace(/,/g, ''))
				valB = parseInt(valB.slice(0, -3).replace(/,/g, ''))
			}
		}
		return $.isNumeric(valA) && $.isNumeric(valB)
			? valA - valB
			: valA.toString().localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' })
	}
}

function getPropertyUrl(str) {
	switch (str) {
		case 'flat':
		case 'house':
			return str
		case 'f':
			return 'flat'
		case 'h':
			return 'house'
		case 'comm':
		case 'c':
			return 'commercial'
		case 'build':
		case 'b':
			return 'building'
		default:
			console.log('Unknown property value: ', str)
	}
}

$().ready(() => {
	if (!$('.flat_section').length && !$('.user_section').length) return

	function sortRows(obj, colNum, switchOrders) {
		var root = '.' + obj + '_section'
		var th = $(root).find('.table-labels')[0].children[colNum]
		var table = $(th).parents('table').eq(0)
		var rows = table.find('tr:gt(1)').toArray().sort(rowComparator(colNum)).reverse()
		th.asc = switchOrders == true ? !th.asc : true
		if (!th.asc) rows = rows.reverse()
		for (var i = 0; i < rows.length; i++) table.append(rows[i])
	}

	function highlightActiveRows(prop) {
		$(`#${prop}_list tr`).each(function () {
			if ($('td:contains("Skryté")', this).length) {
				$(this).addClass('inactive_flat')
			} else {
				$(this).removeClass('inactive_flat')
			}
		})
	}

	function insertOptionsToSelect(element, optionsArr) {
		if (optionsArr.size === 0) return
		const previouslySelected = element.find('option:selected').text().trim()
		element.find('option').remove()
		optionsArr = Array.from(optionsArr)
			.sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
			.reverse()
		element.append(document.createElement('option'))
		for (let i = 0; i < optionsArr.length; i++) {
			var opt = document.createElement('option')
			opt.innerHTML = optionsArr[i]
			element.append(opt)
		}
		element.val(previouslySelected)
	}

	function updateFilterOptions(prop) {
		// all options within a section filter
		const sectionInputs = $(`.${prop}_section .option-filter th`).children()

		// runs only during the initial loading - we're keeping track of select inputs in each section
		const keyExists = prop in selectInputColumns
		if (!keyExists) {
			selectInputColumns[prop] = []
			for (let i = 0; i < sectionInputs.length; i++) {
				if (i === visibleColumn[prop])
					// visibility col is skipped
					continue
				if (sectionInputs[i].tagName == 'SELECT') selectInputColumns[prop].push(i)
			}
		}

		// pre-initialize empty sets
		let optionsMatrix = Array(selectInputColumns[prop].length)
		// length is given by # of select inputs
		// each index represents one set of options
		for (let i = 0; i < optionsMatrix.length; i++) optionsMatrix[i] = new Set()

		// collect data from each row
		let rowArr = $(`#${prop}_list`).children(':visible')
		for (let i = 0; i < rowArr.length; i++) {
			const row = rowArr.eq(i).children()
			// console.log(row);
			selectInputColumns[prop].forEach(function (el, i) {
				optionsMatrix[i].add(row[el].innerText.trim())
				// we're adding vectors of values to a set -> no duplicates
			})
		}

		// update select inputs
		selectInputColumns[prop].forEach(function (inputPosIndex, i) {
			insertOptionsToSelect(sectionInputs.eq(inputPosIndex), optionsMatrix[i])
		})

		insertOptionsToSelect(sectionInputs.eq(visibleColumn[prop]), ['Viditelné', 'Skryté'])
	}

	function resetFilterOptions(prop, resetVisibility = false) {
		// console.log('Reseting filter options for: ', prop)
		if (resetVisibility) {
			$(`.${prop}_section .option-filter input,.${prop}_section .option-filter select`).each(
				function () {
					$(this).val('').trigger('input')
				}
			)
		} else {
			$(`.${prop}_section .option-filter input, .${prop}_section .option-filter select`).each(
				function (i) {
					// console.log($(this))
					if (i === visibleColumn[prop]) return
					$(this).val('').trigger('input')
				}
			)
		}
	}

	function filterRowData(prop) {
		if (prop === 'f') prop = 'flat'
		else if (prop === 'c') prop = 'comm'
		else if (prop === 'h') prop = 'house'
		else if (prop === 'b') prop = 'build'

		const filter = $(`.${prop}_section .option-filter`).find('input, select')

		$(`#${prop}_list`)
			.children()
			.each(function () {
				let r = $(this)
				let c = r.children()
				let invalidRowMatch = false
				console.log(filter)
				r.hide()

				// go trough each cell of the filter row, compare accordingly
				for (let i = 0; i < filter.length; i++) {
					const filterEl = filter[i]

					if (filterEl.tagName === 'SELECT') {
						let cellStr = c[i].innerText
						if (c[i].childElementCount) {
							cellStr = c[i].innerText.trim()
						}
						if (filterEl.value !== '' && filterEl.value !== cellStr) {
							invalidRowMatch = true
							break
						}
					} else if (filterEl.tagName === 'INPUT' && filterEl.type === 'number') {
						const filterStr = parseInt(filterEl.value)
						const hasSuffix = c[i].innerText[c[i].innerText.length - 1] === 'č' // parse out the suffix
						let cellStr = hasSuffix ? c[i].innerText.slice(0, -3) : c[i].innerText
						cellStr = cellStr === '-' ? 0 : parseInt(cellStr.replace('\xa0', ''))
						if (
							isNaN(cellStr) ||
							(
								!isNaN(filterStr) && (
									(filterEl.className === 'lte' && cellStr > filterStr) ||
									(filterEl.className === 'eq' && cellStr != filterStr)
								)
							)
						) {
							invalidRowMatch = true
							break
						}
					} else {
						console.log('Error with filter configuration.')
						// console.log('Filter element: ' + filterEl, filterEl.tagName)
						invalidRowMatch = true
						break
					}
				}

				if (!invalidRowMatch) r.show()
			})
		const column = $('.' + prop + '_section')
			.find('.defaultSort')
			.index()
		sortRows(prop, column, false)
	}

	function findVisibilitySwitch(prop) {
		const thead = $(`.${prop}_section .table-labels`).children()
		let activeIndex = 0
		for (let i = 0; i < thead.length; i++)
			if (thead[i].innerText === 'Viditelnost') {
				activeIndex = i
				break
			}
		return activeIndex
	}

	function setRowVisib(prop, value = 'Viditelné') {
		// if (visibleColumn[prop] === undefined)
		//     console.log('No entry in the visible column dictionary.')
		$(`.${prop}_section .option-filter th`)
			.children()
			.eq(visibleColumn[prop])
			.val(value)
			.trigger('input')
	}

	function showSection(type, preserveOptions = false) {
		let hide = ''
		type === 'flat' ? $('#flats_by_house_filter').show() : $('#flats_by_house_filter').hide()
		PROPERTY_TYPES.forEach((property) => {
			if (property !== type) hide = hide.concat(`.${property}_section, `)
		})
		$(hide.slice(0, -2)).hide()
		$(`.${type}_section`).fadeIn(200).show()

		if (type === 'flat' && !preserveOptions) resetFilterOptions('flat')
	}

	// filter value change listeners
	PROPERTY_TYPES.forEach((prop) => {
		$(`.${prop}_section .option-filter :input`).on(
			'input',
			delay(function (e) {
				const sectionIndicator = $(this).parents().eq(5).attr('class').split(' ')[1][0]
				filterRowData(sectionIndicator)

				// uncomment this for continuous filter option updates
				// caution: doesn't work very well w house filtering
				// updateFilterOptions(property)
			}, FILTER_UPDATE_DELAY)
		)

		$(`.${prop}_section th`)
			.not('.option-filter th')
			.click(function () {
				sortRows(prop, $(this).index(), true)
			})
	})

	// flat filter by house they belong to
	$('.house_filter').click((e) => {
		showSection('flat', true)
		$(`#fAddress`).val(e.currentTarget.innerText).trigger('input')
		setRowVisib('flat')
	})

	// init function
	let visibleColumn = {}
	let selectInputColumns = {}
	if ($('.flat_section').length) {
		PROPERTY_TYPES.forEach((prop) => {
			// add house filtering listeners
			$(`#${prop}_nav`).on('click', function (e) {
				showSection(e.currentTarget.id.slice(0, -4))
			})

			// finds index of a column for visibility control
			visibleColumn[prop] = findVisibilitySwitch(prop)

			// sorts table rows by .defaultSort metaclass in the template head
			let theadToSortBy = $(`.${prop}_section .defaultSort`)
			theadToSortBy.trigger('click')
			highlightActiveRows(prop)
			sortRows(prop, theadToSortBy.index(), false)

			updateFilterOptions(prop)
			setRowVisib(prop)
		})
		showSection('flat')
	}

	PROPERTY_TYPES.forEach(function (prop) {
		const deleteForm = $(`.${prop}DeleteForm`)
		deleteForm.submit(function (e) {
			e.preventDefault()
			$.confirm({
				title: 'Smazání nemovitosti',
				content: 'Opravdu si přejete smazat tuto nemovitost?',
				buttons: {
					confirm: {
						text: 'Potvrdit',
						btnClass: 'btn-red',
						action: () => {
							const propertyId = this.elements['Id'].value
							const propertyUrl = `/${getPropertyUrl(prop)}/${propertyId}/`
							let apiResponse
								; (async () => {
									apiResponse = await deleteObject(propertyUrl)
									let outputMsg = 'Při zpracování požadavku došlo k chybě.'
									if (apiResponse.code === STATUS_OK) {
										switch (prop) {
											case 'flat':
												outputMsg = `Byt "${apiResponse.prop.Addr}" (${apiResponse.prop.flatNr}) byl úspěšně smazán.`
												break
											case 'house':
												outputMsg = `Dům "${apiResponse.prop.Addr}" byl úspěšně smazán.`
												break
											case 'comm':
												outputMsg = `Komerční objekt "${apiResponse.prop.Addr}" byl úspěšně smazán.`
												break
											case 'build':
												outputMsg = `Budova "${apiResponse.prop.Addr}" byla úspěšně smazána.`
												break
										}
										NotifySuccess(outputMsg)
										$(this).parent().parent().remove()
									} else if (apiResponse.code === STATUS_FAIL) {
										switch (prop) {
											case 'flat':
												outputMsg = `Při mazání bytu "${apiResponse.prop.Addr}" (${apiResponse.prop.flatNr}) došlo k chybě.`
												break
											case 'house':
												outputMsg = `Dům "${apiResponse.prop.Addr}" se nepodařilo smazat. Je bez bytů?`
												break
											case 'comm':
												outputMsg = `Při mazání komečního objektu "${apiResponse.prop.Addr}" došlo k chybě.`
												break
											case 'build':
												outputMsg = `Při mazání budovy "${apiResponse.prop.Addr}" došlo k chybě.`
												break
										}
										NotifyWarning(outputMsg)
									} else NotifyWarning(outputMsg)

									deleteForm[0].reset()
								})()
						},
					},
					cancel: {
						text: 'Zrušit',
					},
				},
			})
		})
	})

	const visForm = $('.changeVisibilityForm')
	visForm.submit(function (e) {
		e.preventDefault()
		const hyperlink = $(this).find('#submit')[0]
		const visible = hyperlink.innerText === 'Viditelné'
		$.confirm({
			title: 'Změna viditelnosti',
			content: `Opravdu si přejete ${visible ? 'skrýt' : 'zviditelnit'} tuto nemovitost?`,
			buttons: {
				confirm: {
					text: 'Potvrdit',
					action: () => {
						const objType = $(this).find('input')[0].id[0]
						const objId = $(this).find('#fId,#cId,#hId,#bId')[0].value
						let apiResponse
							; (async () => {
								apiResponse = await updateObject(`/${getPropertyUrl(objType)}/${objId}/`, {
									visible: visible ? false : true,
								})

								// console.log(apiResponse)

								if (apiResponse.code === STATUS_OK)
									NotifySuccess(`Nemovitost č. ${objId} byla ${visible ? 'skryta' : 'zviditelněna'}.`)
								else if (apiResponse.code === STATUS_FAIL)
									NotifyWarning(
										`Při změně viditelnosti nemovitosti objektu č. ${objId} došlo k chybě.`
									)
								else NotifyWarning('Při zpracování požadavku došlo k chybě.')

								visForm[0].reset()
								hyperlink.innerText = visible ? 'Skryté' : 'Viditelné'

								let rowEl = $(this).parent().parent().eq(0)
								hyperlink.innerText === 'Skryté'
									? rowEl.addClass('inactive_flat')
									: rowEl.removeClass('inactive_flat')
							})()
					},
				},
				cancel: {
					text: 'Zrušit',
					action: () => { },
				},
			},
		})
	})
})
