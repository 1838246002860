import $ from 'jquery/dist/jquery'
import { deleteObject } from './ajax'
import { STATUS_OK, STATUS_FAIL } from './ajax'
import { NotifySuccess, NotifyWarning } from './index'

$().ready(() => {
	const usrDeleteForm = $('.deleteUserForm')
	usrDeleteForm.submit(function (e) {
		e.preventDefault()
		$.confirm({
			title: 'Smazání bytu',
			content: 'Opravdu si přejete smazat tohoto uživatele?',
			buttons: {
				confirm: {
					text: 'Potvrdit',
					btnClass: 'btn-red',
					action: () => {
						const userId = this.elements['uId'].value
						const userUrl = `/user/${userId}/`
						let apiRequest = STATUS_FAIL
						;(async () => {
							apiRequest = await deleteObject(userUrl)
							if (apiRequest === STATUS_OK) {
								NotifySuccess(`Uživatel \"${userId}\" byl úspěšně smazán.`)
								$(this).parent().parent().remove()
							} else if (apiRequest === STATUS_FAIL) {
								NotifyWarning(`Uživatel \"${userId}\" nelze smazat.`)
							}
							usrDeleteForm[0].reset()
						})()
					},
				},
				cancel: {
					text: 'Zrušit',
					action: () => {},
				},
			},
		})
	})
})
