import $ from 'jquery/dist/jquery'
import { loadTemplateObject } from './ajax'
import { STATUS_OK, STATUS_FAIL } from './ajax'
import { delay, FILTER_UPDATE_DELAY } from './property'

$().ready(() => {
	let filter = {
		animations: true,
		settings: $('#settings'),
		setting_knobs: $('#setting-knobs'),
		checkbx_ref: ['#flat', '#commercial', '#building'],
		start: $('#start'),

		areCheckBoxesEmpty: () => {
			return !flat.checked && !commercial.checked && !building.checked
		},
		scrollTo: (destination, speed, delay = 800) => {
			if (filter.animations) {
				let jump = $(destination)
				let new_position = $(jump).offset()
				$('html, body').stop().delay(delay).animate({ scrollTop: new_position.top }, speed)
			}
		},
		setCheckBoxState: (control, state) => {
			control.forEach((element) => $(element).prop('checked', state))
		},
		isChecked: (control) => {
			return $(control).is(':checked')
		},
	}

	if (filter.animations) {
		$('#headline').addClass('animated fadeInDown delay-1s')
		$('#filter').addClass('animated bounceInUp delay-2s')
	}

	// x.settings.hide(); TODO: show before production

	// checkbox mechanics
	$('#flat, #commercial, #building').click(function () {
		if (!filter.areCheckBoxesEmpty()) {
			filter.settings.fadeIn(1200).show()
				; (async () => {
					var html = await loadTemplateObject({
						type: 'filter.options',
						flat: filter.isChecked('#flat'),
						comm: filter.isChecked('#commercial'),
						build: filter.isChecked('#building'),
					})
					if (html === STATUS_FAIL) {
						console.log('An error has occured while loading the filter.')
						return
					}
					$('#filter-location').hide()
					$('#filter-extras').html(html).fadeIn('slow')
					$('#filter-location').fadeIn('slow')
				})()
			filter.setting_knobs.hide()
			$('#show-filter').trigger('click')
			filterPropertyData()
		} else filter.settings.hide()
	})

	$('#show-filter').click(function (e) {
		if ($('#setting-knobs').css('display') == 'none') {
			$('#setting-knobs').fadeIn(1500).show()
			$('#show-filter').text('Skrýt vyhledávací filtr')
		} else {
			$('#setting-knobs').toggle()
			$('#show-filter').text('Zobrazit vyhledávací filtr')
		}
	})

	// $('#filter-property').click(function () {
	//     filterPropertyData()
	// });

	$('#back-to-start').click(function () {
		filter.scrollTo(filter.start, 300, 0)
	})

	$('#erase-settings').click(function () {
		$('#filter-location select, #filter-location input[type=number]').each(function () {
			$(this).val('')
		})
		$('#filter-location input[type=checkbox]').each(function () {
			$(this).prop('checked', false)
		})
		filterPropertyData()
	})

	$(document).on(
		'input',
		// '#filter-location :input',
		delay(function (e) {
			filterPropertyData()
		}, FILTER_UPDATE_DELAY)
	)

	// TODO: remove before production
	$('#flat').trigger('click')
})

function getSelectResult(el) {
	const val = $(`#${el}`).val()
	return val === undefined ? '' : val
}

function getCheckBoxResult(el) {
	return $(`#${el}-yes`).prop('checked') === $(`#${el}-no`).prop('checked')
		? ''
		: $(`#${el}-yes`).prop('checked')
}

function getMultiCheckBoxResult(el, options) {
	/*
	Function for value checking for sections with >2 checkboxes in them.
	If all checkboxes are set to false, no options are returned to the result.
	If any option is true, it is included, otherwise dropped from the result. 
	Returns list of options (strings) or an empty string.
	*/
	const values = options.map(opt => $(`#${el}-${opt}`).prop('checked'))
	const allOptionsWelcome = values.every( (val, i, arr) => val === arr[0] )
	if (allOptionsWelcome && !values[0]) 
		return ''
	let includeOptions = []
	for (let i = 0; i < options.length; i++)
		if (values[i])
			includeOptions.push(options[i])
	return includeOptions
}


function getIntervalResult(el) {
	const val_a = $(`#${el}-bot`).val()
	const val_b = $(`#${el}-top`).val()
	return val_a === undefined || val_b === undefined ? '' : [val_a, val_b]
}

async function filterPropertyData() {
	let includeFlat = $('#flat').is(':checked')
	let includeCom = $('#commercial').is(':checked')
	let includeBuild = $('#building').is(':checked')

	let requestBody = {
		flat: includeFlat,
		comm: includeCom,
		build: includeBuild,

		address: getSelectResult('address'),
		room_count: getSelectResult('room_count'),
		rentable: getCheckBoxResult('rent'),
		equipped: getMultiCheckBoxResult('equipped', ['yes', 'no', 'partial']),
		reconstructed: getCheckBoxResult('rec'),

		price: getIntervalResult('price'),
		floor: getIntervalResult('floor'),
		area: getIntervalResult('area'),
	}

	Object.keys(requestBody).forEach(
		(k) =>
			(requestBody[k] === '' || requestBody[k].toString() === ['', ''].toString()) &&
			delete requestBody[k]
	)

	// for (const [key, value] of Object.entries(requestBody)) console.log(key, value)

	const filtered_result = await loadTemplateObject({
		type: 'filter.data',
		options: requestBody,
	})

	$('#filter-data-location #property-listing').hide().html(filtered_result).fadeIn('slow')
	$('#prop-results-cnt').hide().text($('#result-count').text()).fadeIn('slow')
}
