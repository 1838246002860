import { getCookie } from './index'

export const STATUS_OK = 0
export const STATUS_FAIL = 1
export const FILTER_DATA_URI = '/filter_data/'
export const FILTER_OPTIONS_URI = '/filter_options/'
export const IMAGE_EDIT_URI = '/image_edit/'

export async function loadTemplateObject(obj) {
	let request_uri = ''
	switch (obj['type']) {
		case 'filter.options':
			request_uri = FILTER_OPTIONS_URI
			break
		case 'filter.data':
			request_uri = FILTER_DATA_URI
			break
		case 'image.edit':
			request_uri = IMAGE_EDIT_URI + obj['options']
			break
		default:
			console.log('NO URI selected.')
	}
	return await fetch(request_uri, {
		method: 'POST',
		credentials: 'same-origin',
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'X-CSRFToken': getCookie('csrftoken'),
		},
		body: JSON.stringify({
			payload: obj,
		}),
	})
		.then((response) => response.text())
		.catch((error) => {
			console.log('API failure: ' + error)
			return STATUS_FAIL
		})
}

export async function updateObject(url, objData) {
	return await fetch(url, {
		method: 'PUT',
		credentials: 'same-origin',
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'X-CSRFToken': getCookie('csrftoken'),
		},
		body: JSON.stringify({
			payload: objData,
		}),
	})
		.then((response) => response.json())
		.catch((error) => {
			console.log('API failure: ' + error)
			return STATUS_FAIL
		})
}

export async function deleteObject(url) {
	return await fetch(url, {
		method: 'DELETE',
		credentials: 'same-origin',
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'X-CSRFToken': getCookie('csrftoken'),
		},
	})
		.then((response) => response.json())
		.catch((error) => {
			console.log('API failure: ' + error)
			return STATUS_FAIL
		})
}
