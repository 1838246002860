import $ from 'jquery'
import '@popperjs/core/dist/cjs/popper'
import 'bootstrap/dist/js/bootstrap.min'
import lightbox from 'lightbox2/dist/js/lightbox'
import 'slick-carousel/slick/slick.min'
import Swiper from 'swiper/bundle'
import '../../node_modules/jquery-confirm/dist/jquery-confirm.min.js'
import Toastify from 'toastify-js'

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/bundle'
import * as FilePond from 'filepond/bundle'
import pt_CS from 'filepond/czech'

import '../scss/style.scss'

export function getCookie(name) {
	let cookieValue = null
	if (document.cookie && document.cookie !== '') {
		const cookies = document.cookie.split(';')
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim()
			if (cookie.substring(0, name.length + 1) === name + '=') {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
				break
			}
		}
	}
	return cookieValue
}

const TOAST_OFFSET_X = 0
const TOAST_OFFSET_Y = 30
const TOAST_GRAVITY = 'top'
const TOAST_POSITION = 'right'
const TOAST_DURATION = 6000
const TOAST_CLOSE = false

const LBOX_LOAD_MS = 500
const LBOX_PICTURE_NOTE = 'Fotografie %1 z %2'
const LBOX_TOP_PADDING = 150
const LBOX_WRAPPING = false

// const SWIPER_LOOPING = true
// const SWIPER_LOOPING_WITH_BLANK = false
// const SWIPER_AUTOPLAY_DELAY_MS = 3000
// const SWIPER_AUTOPLAY_DISABLE_ON_INTERACTION = true
// const SWIPER_SCROLLBAR_HIDE = true

var swiper = new Swiper('.mySwiper', {
	// effect: 'fade',
	// loop: SWIPER_LOOPING,
	// loopFillGroupWithBlank: SWIPER_LOOPING_WITH_BLANK,
	// autoplay: {
	//   delay: SWIPER_AUTOPLAY_DELAY_MS,
	//   disableOnInteraction: SWIPER_AUTOPLAY_DISABLE_ON_INTERACTION,
	// },
	centeredSlides: true,
	centeredSlidesBounds: true,
	allowTouchMove: false,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
	},
	mousewheel: {
		invert: false,
	},
	breakpoints: {
		640: { slidesPerView: 1, spaceBetween: 10 },
		768: { slidesPerView: 1, spaceBetween: 10 },
		1024: { slidesPerView: 3, spaceBetween: 10 },
		1600: { slidesPerView: 4, spaceBetween: 10 },
	},
})

window.lightbox = lightbox
lightbox.option({
	resizeDuration: LBOX_LOAD_MS,
	wrapAround: LBOX_WRAPPING,
	albumLabel: LBOX_PICTURE_NOTE,
	positionFromTop: LBOX_TOP_PADDING,
})

export function NotifySuccess(msg, duration = TOAST_DURATION, icon = '\u2713') {
	Toastify({
		text: icon + ' ' + msg,
		offset: {
			x: TOAST_OFFSET_X,
			y: TOAST_OFFSET_Y,
		},
		className: 'toast-success',
		duration: duration,
		gravity: TOAST_GRAVITY,
		position: TOAST_POSITION,
		close: TOAST_CLOSE,
	}).showToast()
}

export function NotifyWarning(msg, duration = TOAST_DURATION, icon = '\u2715') {
	Toastify({
		text: icon + ' ' + msg,
		offset: {
			x: TOAST_OFFSET_X,
			y: TOAST_OFFSET_Y,
		},
		className: 'toast-warning',
		duration: duration,
		gravity: TOAST_GRAVITY,
		position: TOAST_POSITION,
		close: TOAST_CLOSE,
	}).showToast()
}

export function NotifyError(msg, duration = TOAST_DURATION, icon = '\u2715') {
	Toastify({
		text: icon + ' ' + msg,
		offset: {
			x: TOAST_OFFSET_X,
			y: TOAST_OFFSET_Y,
		},
		className: 'toast-error',
		duration: duration,
		gravity: TOAST_GRAVITY,
		position: TOAST_POSITION,
		close: TOAST_CLOSE,
	}).showToast()
}

FilePond.registerPlugin(FilePondPluginFileValidateSize, FilePondPluginImagePreview)
FilePond.setOptions(pt_CS)
FilePond.setOptions({ storeAsFile: true })
FilePond.create(document.getElementsByClassName('id_photos')[0])

document.addEventListener('FilePond:warningf', (e) => {
	if (e.detail.error.body == 'Max files') NotifyError('Byl překročen maximální počet souborů.')
})

$(function () {
	$('#logout').on('click', function () {
		$.confirm({
			title: 'Odhlášení',
			content: 'Určitě si přejete se odhlásit?',
			buttons: {
				confirm: {
					text: 'Potvrdit',
					btnClass: 'btn-dark',
					action: () => {
						window.location.href = '/logout'
					},
				},
				cancel: {
					text: 'Zrušit',
					action: () => { },
				},
			},
		})
	})
})
